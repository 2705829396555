<template>
  <div>
    <div class="page-heading" style="margin-bottom: 0px;">
      <div class="row">
        <div class="col-sm-12">
          <div class="heading-content">
            <div class="heading-title">
              <p>
                Você no Controle de suas finanças!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="card no-shadow" style="border: 1px solid #dee2e6 !important;">
      <div class="card-header text-center">
        <img src="/assets/images/premium.png" class="nothing-image img-responsive" style="width:80px; margin: 0 auto;;">
        <br>
          <h4 v-if="this.$route.params.expire && !isPremium">Seu período de testes acabou.</h4>
          <h4 v-else>Aproveite todos os recursos do Stimper.</h4>
          Conheça nosso plano e assine agora para uma melhor gestão de suas finanças.
          <br>
        <br>
      </div>
      <div class="row" v-if="!showPremium">
        <div class="col-sm-12">
          <div class="space-content" style="padding-bottom: 200px;">
            <div class="loading-overlay-inside" style="background-color:rgba(298, 294, 290, 0.9); margin-top: 0px;"><div class="loader-box"><div class="circle-loader" style="top: 0;"></div></div></div>
          </div>
        </div>
      </div>
      <div class="row" v-if="showPremium">
        <div class="col-sm-12"><hr></div>
        <div class="col-sm-12">
          <div class="space-content">
            <div class="sem-permissao row">
              <div class="col-sm-6 col-lg-6 col-md-6 col-xs-12" style="padding: 20px;">
                <div class="grid__row text-center">
                  <div class="text-center">
                    <img :src="`assets/images/premium_service.png`" style="width: 25%; margin-top: 20px; margin-bottom: 20px;">
                    <p>
                      Atualize para o Stimper Premium e tenha acesso a todos os recursos do app.
                    </p>
                    <ul class="text-left sem-permissao-lista" style="padding-left: 0px !important;">
                      <li class="sem-permissao-lista__item">
                        <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 24 24" width="14">
                          <path d="M0 0h24v24H0z" fill="none"></path>
                          <path fill="#37A33B" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z">
                          </path>
                        </svg>
                        Contas ilimitadas
                      </li>
                      <li class="sem-permissao-lista__item">
                        <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 24 24" width="14">
                          <path d="M0 0h24v24H0z" fill="none"></path>
                          <path fill="#37A33B" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z">
                          </path>
                        </svg>
                        Cartões ilimitados
                      </li>
                      <li class="sem-permissao-lista__item">
                        <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 24 24" width="14">
                          <path d="M0 0h24v24H0z" fill="none"></path>
                          <path fill="#37A33B" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z">
                          </path>
                        </svg>
                        Orçamentos
                      </li>
                      <li class="sem-permissao-lista__item">
                        <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 24 24" width="14">
                          <path d="M0 0h24v24H0z" fill="none"></path>
                          <path fill="#37A33B" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z">
                          </path>
                        </svg>
                        Contas recorrentes
                      </li>
                      <li class="sem-permissao-lista__item">
                        <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 24 24" width="14">
                          <path d="M0 0h24v24H0z" fill="none"></path>
                          <path fill="#37A33B" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z">
                          </path>
                        </svg>
                        Relatórios
                      </li>
                      <li class="sem-permissao-lista__item">
                        <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 24 24" width="14">
                          <path d="M0 0h24v24H0z" fill="none"></path>
                          <path fill="#37A33B" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z">
                          </path>
                        </svg>
                        E muito mais...
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-lg-6 col-md-6 col-xs-12">
                <div class="sem-permissao__coluna-plano" style="border: 1px solid rgb(222, 226, 230) !important; border-radius: 13px;">
                  <div v-if="!showPayment && isPremium">
                    <div class="price-box-premium">
                      <span class="to-left">R$</span><h3 class="to-left">9,90</h3>
                      <strong>/Mês</strong>
                      <div style="clear:both;"></div>
                      <hr>
                    </div>
                    <h1 class="mb-5">Premium</h1>
                    <p style="color: #b7b7b7;">Parabéns, você é um usuário premium.</p>
                    <div >
                      <button @click="cancel" class="btn btn-danger"
                        :class="{ 'qt-loader qt-loader-mini qt-loader-right': isSending }"
                        :disabled="isSending">Cancelar</button>
                    </div>
                  </div>
                  <div v-if="!showPayment && !isPremium">
                    <div class="price-box-premium">
                      <span class="to-left">R$</span><h3 class="to-left">9,90</h3>
                      <strong>/Mês</strong>
                      <div style="clear:both;"></div>
                      <hr>
                    </div>
                    <h1 class="mb-5">Seja premium</h1>
                    <p style="color: #b7b7b7;">Compra 100% segura, seus dados estão seguros.</p>
                    <div >
                      <button @click="showCard" class="btn btn-info"
                        :class="{ 'qt-loader qt-loader-mini qt-loader-right': isSending }"
                        :disabled="isSending">Quero ser premium</button>
                    </div>
                  </div>
                  
                  <div v-if="showPayment">
                    <div class="price-box-premium">
                      <span class="to-left">R$</span><h3 class="to-left">9,90</h3>
                      <strong>/Mensal</strong>
                      <div style="clear:both;"></div>
                      <hr>
                    </div>
                    <h1 class="mb-5">Assinar</h1>
                    <p style="color: #b7b7b7;">Compra 100% segura, seus dados estão seguros.</p>
                    <stripe-element-card
                      ref="elementRef"
                      :pk="publishableKey"
                      @token="tokenCreated"
                    />
                    <br>
                    <button class="btn btn-success" @click="submit()"
                      :class="{ 'qt-loader qt-loader-mini qt-loader-right': isSending }"
                      :disabled="isSending"
                    >
                      Finalizar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { StripeElementCard } from '@vue-stripe/vue-stripe';

import AppService from '@/services/app.service';
import pace from 'pace-js';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';

export default {
  name: 'Home',
  components: {
    StripeElementCard,
  },
  computed: {
    classes() {
      return ['wizard'];
    },
    info() {
      return JSON.parse(localStorage.getItem('stimper_user'));
    },
  },
  data() {
    return {
      form: {},
      isSending: false,
      showPayment: false,
      publishableKey: 'pk_test_51JTB8QGEDM5bJO5GrXVju8Gtu8bvcVfT1cvPxwx9LgrCMhOtG9ommlEkFtrwJsrZAulpfP5O5TdiegcXZ71d1n0500q8WJXWMu',
      loading: false,
      showPremium: false,
      isPremium: false,
    };
  },
  provide: {
  },
  mounted () {
    window.scrollTo(0, 0)
  },
  created() {
    moment.locale('pt_BR');
    pace.start();

    this.from = `${moment(new Date()).format('YYYY-MM')}-01`;
    this.to = moment(this.from, "YYYY-MM-DD").endOf('month');
    this.request();
  },
  methods: {
    cancel() {
      Swal.fire({
        title: `Cancelar assinatura?`,
        text: 'Tem certeza que deseja cancelar a assinatura?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
      }).then((result) => {
        if (result.value) {
          this.cancelSubscription();
        }
      });
    },
    cancelSubscription() {
      this.isSending = true;
      AppService.cancelSubscription().then(
        (response) => {
          this.isSending = false;
          this.showPayment = false;
          console.log(response);
          if (response.status == 200) {
            Swal.fire(
              'Assinatura cancelada',
              'Sua assinatura foi cancelada',
              'success',
            );
            this.request();
          }
        },
        (error) => {
          console.log(error);
          this.content = error;
          this.isSending = false;
        },
      );
    },
    submit () {
      this.$refs.elementRef.submit();
      this.isSending = true;
    },
    tokenCreated (token) {
      console.log(token.id);
      AppService.subscription({
        platform: 'stripe',
        product_id: 'starter',
        card_token: token.id,
      }).then(
        (response) => {
          this.isSending = false;
          if (response.status == 200) {
            Swal.fire(
              'Assinatura ativa',
              'Sua assinatura premium está ativa',
              'success',
            );
            this.request();
          }
        },
        (error) => {
          console.log(error);
          this.content = error;
          this.isSending = false;
        },
      );
    },
    showCard() {
      this.isSending = true;
      setTimeout(() => {
        this.showPayment = true;
        this.isSending = false;
      }, 300);
    },
    request() {
      AppService.getInfo({
        from: this.from,
        to: this.to,
      }).then(
        (response) => {
          this.showPremium = true;
          console.log('User Info');
          console.log(response);
          if (response.user.is_premium) {
            this.isPremium = true;
          } else {
            this.isPremium = false;
          }
          this.showPayment = false;
          this.$forceUpdate();
        },
        (error) => {
          console.log(error);
          this.content = error;
        },
      );
    },
  },
};
</script>

<style scoped lang="scss">
.cashflow-print {
  position: absolute;
  right: 0;
  margin-right: 10px;
  margin-top: 10px;
  cursor: pointer;
}
.page-heading {
  margin-bottom: 0px;
}
.chart {
  height: 200px;
}
.chart-line {
  height: 350px;
  margin-top: 0px;
}
.swiper-slide{
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.swiper-container {
  height : 500px;
}
.figure-stats {
  background-color: #F9F9F9;
  border: 1.2px solid #E7EAEE;
  margin-top: 0px;
}
.card-header {
  border-bottom: none;
}
.figure-section {
  padding-top: 10px;
  padding-bottom: 10px;
}
.heading-title {
  padding-left: 0px;
}
.total-amount {
  margin-top: 10px;
  display: block;
}
.btn-remove-filter {
  height: 35px !important;
  border-radius: 50%;
  width: 35px;
  padding: 0px !important;
  text-align: center;
}
.btn-remove-filter i {
  margin-right: 0;
}
.grid__row {
    display: flex;
  }

  .grid__col,
  .grid__col-prefix {
    flex: 1 0 auto;
    max-width: 100%;
  }

  .sem-permissao__conteudo {
    padding: 70px 20px;
    border-radius: 8px 0 0 8px;
  }

  .margin-large-left {
    margin-left: 2rem;
  }

  .sem-permissao__conteudo h1 {
    color: #37A33B;
    font-size: 19px;
    font-weight: 700;
  }

  .sem-permissao__conteudo h1,
  .sem-permissao__conteudo strong {
    color: #37A33B;
    margin: 0;
  }

  .sem-permissao__conteudo P {
    font-size: 14px;
    color: rgba(0, 0, 0, .8705882352941177);
    margin: 0;
  }

  .sem-permissao-lista {
    margin-top: 15px;
  }

  .sem-permissao__coluna-plano {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px;
    background-color: rgba(240, 244, 250, .6784313725490196);
    border-radius: 0 8px 8px 0;
  }

  .sem-permissao__coluna-plano>div {
    width: 100%;
  }

  .sem-permissao-plano__titulo {
    display: block;
    font-size: 12px;
    color: rgba(0, 0, 0, .6);
    font-weight: 700;
    margin-bottom: 8px;
  }

  .sem-permissao-plano {
    border-radius: 5px;
    line-height: normal;
    background: #fff;
    border: 1px solid #e9ecef;
  }

  .sem-permissao-lista__item {
    list-style: none;
  }

  .sem-permissao-plano__cabecalho {
    position: relative;
    background: #fff;
    padding: 25px 0;
    border-bottom: 1px solid #ededed;
    border-radius: 5px 5px 0 0;
    height: 140px;
    text-align: center;
  }

  .sem-permissao-plano__cabecalho-nome {
    display: block;
    font-size: 20px;
    color: rgba(0, 0, 0, .6);
    font-weight: 700;
  }

  .sem-permissao-plano__cabecalho-preco {
    font-size: 38px;
    font-weight: 700;
    color: #37A33B;
  }

  .sem-permissao-plano__cabecalho-preco:before {
    content: "R$";
    font-size: 15px;
    font-weight: 700;
    vertical-align: bottom;
    margin-right: 5px;
    margin-bottom: 6px;
    display: inline-block;
    color: #37A33B;
  }

  .sem-permissao-plano__cabecalho-descricao {
    font-size: 10px;
    color: rgba(0, 0, 0, .6);
  }

  .sem-permissao-plano__label {
    position: absolute;
    bottom: 10px;
    left: 12px;
    font-size: 10px;
    text-align: left;
    font-weight: 700;
    color: rgba(0, 0, 0, .3686274509803922);
  }

  .plano-lista__item {
    display: flex;
    background-color: #f1f6fa;
    border-bottom: 1px solid #e9ecef;
  }

  .plano-lista__texto {
    display: flex;
    flex: 1 1 100%;
    align-items: center;
    font-size: 12px;
    font-weight: 700;
    padding: 13px;
  }

  .plano-lista__icon {
    width: 50px;
    flex: 0 0 50px;
    border-left: 1px solid #e9ecef;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDoAABSCAABFVgAADqXAAAXb9daH5AAAAEgSURBVHjarJQ/SkNBEIe/tzmAJxBvYSVsITL+RYRgJbYSPcCeYUgZidYKNnbBZm1kjAQbPYtP7AI+myQ84kt8Jjvd7vzm21l29pcVRUHKyJYpDtGvAA9AW8V6SwFHsEdgHRgCTRXrZQlg4xgCzUYiGEADWM0SwQByYM2VxSH65oKwL2BfxXI3Jb4P0bf+CfsEtlTsBcBViLtlaI1r7qjY62QOQ/R9YKNCfA7c/QE7ULF+edMBbeC7oqALvM+BHU3DJoMdor8BTmoO+gdwqGLPVUkHoGKnwDVQ1IDtzYL9+noh+ivgbEanObCrYoN5J7ryQsVawGVFp+PXHCzkNiH6DnAxyufApoq9LWVfIfpbYBs4VrGn2n6Y2mAdiSM58GcAlMdt0B++Jh4AAAAASUVORK5CYII=) no-repeat 50%;
  }

  .plano-lista {
    margin: 0px;
    padding: 0px;
  }

  .sem-permissao-plano__rodape {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    padding: 16px 0;
  }

  .button-flat--primary {
    background-color: #37A33B;
    color: #fff;
  }

  .button-flat--normal {
    font-size: 16px;
    height: 40px;
  }

  .button-flat {
    display: flex;
    align-items: center;
    text-align: center;
    border-radius: 4px;
    font-weight: 700;
    padding: 0;
    transition: box-shadow .5s, .5s, color .5s;
  }

.price-box-premium span{
  color: #37a33b;
}
.price-box-premium h3{
  color: #37a33b;
  font-size: 70px;
  margin: 0px;
  line-height: 0.9;
}
.price-box-premium strong {
  font-size: 14px;
  font-weight: initial;
}
</style>
